export default {
  isLoggedIn(state) {
    return Boolean(state.info);
  },
  isActiveLoans(state) {
    return ((state.info || {}).active_loans || []).length !== 0;
  },
  isActiveApplication(state) {
    return Boolean((state.info || {}).active_application);
  },
  isOldUser(state) {
    return (state.info || {}).is_old_user;
  },
  isWithoutActive(state, getters) {
    const isActivePage = getters.isActiveLoans || getters.isActiveApplication;

    return !isActivePage;
  },
  isHystory(state) {
    return Boolean((state.history || {}).number);
  },
  isNotification(state) {
    return Boolean((state.notification || {}).number);
  },
  isSnackbarTrafficShow(state) {
    const activeLoans = ((state.info || {}).active_loans || {})[0];
    const delayInDays = (activeLoans || {}).delay_in_days || 0;

    return (
      (Boolean(!activeLoans) || state.popupName !== null) &&
      delayInDays <= 5 &&
      state.popupName !== "popup-last-day"
    );
  },
  clientInfoForVerbox(state) {
    const info = state.info || {};
    const { borrower_uuid } = state.payInfo || {};
    const dict = {
      krediska: "krediska",
      beriberu: "beriberu",
      hurma: "hurmacredit",
      dabank: "davaka",
    };

    return info.name
      ? {
          name: info.name + " " + info.patronymic + " " + info.surname,
          phone: info.phone,
          email: info.email,
          custom: {
            loan_id: ((info.active_loans || [])[0] || {}).id,
            borrower_uuid,
            link:
              "https://operator." +
              dict[window.envWebpack.theme] +
              ".ru/clients/borrower/" +
              borrower_uuid,
          },
        }
      : {
          name: "Незарегистрированный",
        };
  },
};
