import infra from "@frontend/allspark/infra/index_beriberu-lk.js";

const $infra = infra.infra;

export default {
  namespaced: true,
  state: () => ({
    params: {
      limit: 5, //Отображается максимум 5 сообщений на странице.
      offset: 0,
    },
    page: 1,
    result: {
      count: 0,
      unreadCount: 0,
      messages: [],
    },
  }),
  actions: {
    getMessages({ commit, state }) {
      return $infra.jsonrpc
        .getMessages(state.params)
        .then((res) => {
          if (res.messages) {
            commit("_setResult", res);
            return res;
          }

          throw res;
        })
        .catch((res) => {
          console.error(res);
          throw res;
        });
    },
    readMessage({ commit }, messageId) {
      return $infra.jsonrpc
        .readMessage(messageId)
        .then((res) => {
          if (res.status === "success") {
            commit("_setReadMessage", messageId);
            return res;
          }

          throw res;
        })
        .catch((res) => {
          console.error(res);
          throw res;
        });
    },
  },
  mutations: {
    _setResult(state, res) {
      state.result = res;
    },
    _setReadMessage(state, messageId) {
      const message = state.result.messages.find(
        (item) => item.id === messageId
      );

      if (message && !message.isRead) {
        state.result.unreadCount = state.result.unreadCount - 1;
        message.isRead = true;
      }
    },
    setOffsetByPage(state, page) {
      state.page = page;
      state.params.offset = (page - 1) * state.params.limit;
    },
  },
  getters: {
    messages(state) {
      return state.result.messages;
    },
    count(state) {
      return state.result.count;
    },
    countBoolean(state) {
      return Boolean(state.result.count);
    },
    unreadCount(state) {
      return state.result.unreadCount;
    },
    paginationParams(state) {
      const count = state.result.count || 1;
      const limit = state.params.limit || 1;

      return {
        current: state.page,
        last: Math.ceil(count / limit) || 1,
      };
    },
  },
};
