export default {
  computed: {
    theme() {
      return (window.envWebpack || {}).theme || "krediska";
    },
    toColorName() {
      return this.themeDic(
        {
          beriberu: "red",
          hurma: "orange",
          dabank: "green",
        },
        ""
      );
    },
    toColor() {
      return this.themeDic(
        {
          beriberu: "#bd2b3d",
          hurma: "#F05B01",
          krediska: "#007AFF",
          dabank: "#00C308",
        },
        ""
      );
    },
  },
  methods: {
    themeDic(dic, def) {
      return dic[this.theme] || def;
    },
  },
};
