export default {
  setInfo(state, info) {
    state.info = info;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setHistory(state, history) {
    state.history = history;
  },
  setLoanDocuments(state, documentsLoan) {
    state.documentsLoan = documentsLoan;
  },
  setDocumentsIdArchive(state, documentsIdArchive) {
    state.documentsIdArchive = documentsIdArchive;
  },
  setDocumentsProlongationFree(state, documentsProlongationFree) {
    state.documentsProlongationFree = documentsProlongationFree;
  },
  setDocumentsProlongation(state, documentsProlongation) {
    state.documentsProlongation = documentsProlongation;
  },
  setPayInfo(state, payInfo) {
    state.payInfo = payInfo;
  },
  setPopupName(state, popupName) {
    state.popupName = popupName;
  },
  setBannerType(state, bannerType) {
    state.bannerType = bannerType;
  },
  setNotification(state, notification) {
    state.notification = notification;
  },
};
