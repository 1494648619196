import { createRouter as _createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/active",
    name: "ActiveLoan",
    component: () =>
      import(/* webpackChunkName: "active" */ "@/views/active/index.vue"),
    meta: {
      layout: "layout-default",
      layoutWithoutPadding: true,
      requiresAuth: true,
    },
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "history" */ "@/views/history/index.vue"),
    meta: {
      layout: "layout-default",
      requiresAuth: true,
    },
  },
  {
    path: "/inform",
    name: "Notification",
    component: () =>
      import(
        /* webpackChunkName: "notification" */ "@/views/notification/index.vue"
      ),
    meta: {
      layout: "layout-default",
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: () =>
      import(/* webpackChunkName: "messages" */ "@/views/messages/index.vue"),
    meta: {
      layout: "layout-default",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/profile/index.vue"),
    meta: {
      layout: "layout-default",
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/auth.vue"),
    meta: {
      layout: "layout-default",
      layoutVCenter: window.envWebpack.theme == "krediska",
    },
  },
  {
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/error/index.vue"),
    meta: {
      layout: "layout-default",
      layoutVCenter: true,
    },
  },
  {
    path: "/redirect/*",
    name: "redirect",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/redirect.vue"),
    meta: {
      layout: "layout-default",
      layoutVCenter: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "@frontend/beryberu-main/views/404/404.vue"
      ),
    meta: {
      layout: "layout-default",
      layoutVCenter: true,
    },
  },
  {
    path: "/partner",
    name: "ShowcaseMini",
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "@/views/showcase-mini/showcase-mini.vue"
      ),
    meta: {
      layout: "full-screen",
      requiresAuth: true,
      theme: "beriberu",
    },
  },
  {
    path: "/overdue-payment",
    name: "OverduePayment",
    component: () =>
      import(
        /* webpackChunkName: "overdue-payment" */ "@/views/overdue-payment/index.vue"
      ),
    meta: {
      layout: "full-screen",
      requiresAuth: true,
    },
  },
];

export function createRouter(initObj) {
  const router = _createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes,
  });

  router.beforeEach((to, from, next) => {
    if ((localStorage || {}).setItem && to.query.urlSuccess) {
      localStorage.setItem("urlSuccess", Number(new Date()) + "");
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (initObj.store.getters["auth/isLoggedIn"]) {
        initObj.store.dispatch("messages/getMessages");
        next();
        return;
      }

      next({ name: "Auth" });
    } else {
      next();
    }
  });

  return router;
}
