// @ts-check

export default () => ({
  info: null,
  documentsLoan: [],
  documentsIdArchive: null,
  documentsProlongation: [],
  documentsProlongationFree: [],
  payInfo: {},
  history: {},
  profile: {},
  popupName: null,
  notification: [],
});
