<template>
  <div class="full-screen d-flex flex-column">
    <main :class="[$route.meta.layoutVCenter && 'flex-grow-1', 'layout-col']">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "FullScreen",
};
</script>

<style>
.full-screen {
  min-height: 100%;
}
</style>
