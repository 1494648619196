import { createApp } from "vue";
import { createStore } from "@/store/index.js";
import { createRouter } from "@/router/index.js";

import App from "./app.vue";
import { register } from "register-service-worker";

import serviceFilterLayer from "@frontend/beryberu-main/services/filters/vue-plugin.js";

export default function ({ infraLayer, UiKit, swName, Layouts }) {
  register(`${process.env.BASE_URL}${swName}`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  // eslint-disable-next-line sonarjs/prefer-object-literal
  const initObj = {};
  initObj.store = createStore(initObj);
  initObj.router = createRouter(initObj);

  const app = createApp(App);

  app.use(initObj.router);
  app.use(initObj.store);
  app.use(infraLayer);
  app.use(UiKit);
  app.use(serviceFilterLayer);
  app.use(Layouts);

  app.mount("#app");
}
