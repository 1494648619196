import { createStore as _createStore } from "vuex";

import auth from "./modules/auth/index.js";
import messages from "./modules/messages.js";

export function createStore() {
  return _createStore({
    modules: {
      auth,
      messages,
    },
    strict: true,
  });
}
